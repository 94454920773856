import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moneyImage from '../../assets/images/money.png'
import researchSolutionImage from '../../assets/images/research_solution.jpg'
import researchStep3Image from '../../assets/images/research_step3.jpg'
// Contexts
import { useConfiguration } from '../../context/configurationContext.js';
import { useRouteConfiguration } from '../../context/routeConfigurationContext.js';
// Utils
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl.js';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types.js';
import { types as sdkTypes } from '../../util/sdkLoader.js';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers.js';
import { convertMoneyToNumber } from '../../util/currency.js';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data.js';
import { richText } from '../../util/richText.js';
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction.js';

// Global ducks (for Redux actions and thunks)
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck.js';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck.js';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import IMG1 from './img/img1.webp';
import IMG2 from './img/img2.webp';

// Shared components
import {
  H1,
  H2,
  H3,
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
} from '../../components/index.js';

// Related components and modules
import TopbarContainer from '../TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';
import NotFoundPage from '../NotFoundPage/NotFoundPage.js';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  handleUploadDocumentToBlob,
  couponValidation,
} from './ListingPage.duck.js';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared.js';
import ActionBarMaybe from './ActionBarMaybe.js';
import SectionTextMaybe from './SectionTextMaybe.js';
import SectionReviews from './SectionReviews.js';
import SectionAuthorMaybe from './SectionAuthorMaybe.js';
import SectionMapMaybe from './SectionMapMaybe.js';
import SectionGallery from './SectionGallery.js';
import CustomListingFields from './CustomListingFields.js';

import css from './ListingPagePreBooking.module.css';
import { signup } from '../../ducks/auth.duck.js';
import { getRandomPassword } from '../../util/userHelpers.js';
import { message } from 'antd';
import useNotification from '../../hooks/notification.js';
import { USER_TYPE_MAPPING } from '../../config/configUser.js';
import SectionPrebookingFooter from '../PageBuilder/SectionBuilder/SectionPrebookingFooter/SectionPrebookingFooter.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPagePreBookingComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    handleUploadDoc,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    handleCouponValidation
  } = props;

  const notification = useNotification();

  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };
  const address = 'currentUser?.attributes?.profile?.publicData?.Address?.selectedPlace?.origin?.lng'
  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(transactionProcessAlias?.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && (processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected);
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const errorMessage = intl.formatMessage({ id: 'ListingPage.preBookingEmailExist' });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    values.preBooking = true;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {

      if(props?.currentUser) {
        onSubmit(values);
        return;
      }

      props?.onSignUp && props?.onSignUp({
        email: values?.studentEmail,
        password: getRandomPassword(),
        firstName: values?.studentFirstName,
        lastName: values?.studentLastName,
        publicData: {
          Address: values?.address,
          whatsappPhoneNumber: values?.studentWhatsappNumber,
          userType: USER_TYPE_MAPPING.STUDENT,
        },
        privateData: {},
        protectedData: {}
      })?.then((res) => {
        if (res?.status >= 200 && res?.status < 300) {
          onSubmit(values);
        }
        else {
          notification.notify(errorMessage, 'error');
        }
      })?.catch(() => {
        notification.notify(errorMessage, 'error');
      });
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <section className={css.steps}>
          <div className={css.preBookingStepBlock}>
            <div className={css.heading}>
              <H2 as="h1" className={css.steperPaymentHeading}>
                <FormattedMessage id="ServicePaymentMethodsPage.heading" />
              </H2>
            </div>
            <div className={css.section}>
              <div className={css.section1}>
                <div className={css.sectionimage}>
                  <img src={moneyImage} alt="Payment" />

                </div>
                <div className={css.stepNumber}>
                  <H1 className={css.stepNumber}> 1.</H1>

                </div>
                <div className={css.blockContent}>
                  <H3 className={css.preBookingHeading}>
                    <FormattedMessage id="ServicePaymentMethodsPage.step1content" />

                  </H3>
                </div>
              </div>
              <div className={css.section1}>
                <div className={css.sectionimage}>
                  <img src={researchSolutionImage} alt="Payment" />

                </div>
                <div className={css.stepNumber}>
                  <H1 className={css.stepNumber}> 2.</H1>

                </div>
                <div className={css.blockContent}>
                  <H3 className={css.preBookingHeading}>
                    <FormattedMessage id="ServicePaymentMethodsPage.step2content" />
                  </H3>
                </div>
              </div>
              <div className={css.section1}>
                <div className={css.sectionimage}>
                  <img style={{ borderRadius: 70 }} src={researchStep3Image} alt="Payment" />

                </div>
                <div className={css.stepNumber}>
                  <H1 className={css.stepNumber}> 3.</H1>

                </div>
                <div className={css.blockContent}>
                  <H3 className={css.preBookingHeading}>
                    <FormattedMessage id="ServicePaymentMethodsPage.step3content" />
                  </H3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>


            <section className={css.blockContent}>

            </section>
            {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              />
            ) : null}
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}

            <div className={css.testimonialSection}>
              <div className={css.testimonialSectgion}>
                <h2>Fideline</h2>
                <p><FormattedMessage id="ListingPage.feedBackHeading1" /></p>
                <p><FormattedMessage id="ListingPage.feedBackDescription1" /></p>
              </div>
              <div>
                <img src={IMG1} width={100} className={css.testimonialImg} />
              </div>
            </div>
            <div className={css.testimonialLearnMore}>
              <a href="https://world-like-home.community/t/etudiant-comment-avoir-une-famille-d-accueil-attestation-dhebergement-avec-world-like-home/5115" target='_blank' ><FormattedMessage id="ListingPage.learnMore" /></a>
            </div>
            <div className={css.testimonialSection}>
              <div className={css.testimonialSectgion}>
                <h2><FormattedMessage id="ListingPage.feedBackTitle1" /></h2>
                <p><FormattedMessage id="ListingPage.feedBackHeading2" /></p>
                <p><FormattedMessage id="ListingPage.feedBackDescription2" /></p>
                <p>
                  <FormattedMessage id="ListingPage.feedBackDescription3" />
                </p>
                <p>
                  <FormattedMessage id="ListingPage.feedBackDescription4" />
                </p>
              </div>
              <div>
                <img src={IMG2} width={100} className={css.testimonialImg} />
              </div>
            </div>






            {/*<SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />*/}

          </div>
          <div className={css.orderColumnForProductLayout}>
            {address ?
              <OrderPanel
                className={css.productOrderPanel}
                listing={currentListing}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                authorLink={
                  <NamedLink
                    className={css.authorNameLink}
                    name="ListingPage"
                    params={params}
                    to={{ hash: '#author' }}
                  >
                    {authorDisplayName}
                  </NamedLink>
                }
                title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  </H4>
                }
                payoutDetailsWarning={payoutDetailsWarning}
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                validListingTypes={config.listing.listingTypes}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                marketplaceName={config.marketplaceName}
                redirectFromPreBooking={true}
                handleUploadDoc={file => handleUploadDoc(file, config)}
                handleCouponValidation={handleCouponValidation}
                config={config}
                currentUser={currentUser}
                isPreBooking={true}
              />
              : <p className={css.error}><FormattedMessage id="ListingPage.AddressError" values={{ title: richTitle }} /></p>}
          </div>
        </div>
          <SectionPrebookingFooter />
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPagePreBookingComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPagePreBookingComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  handleUploadDoc: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPagePreBookingComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  handleUploadDoc: data => dispatch(handleUploadDocumentToBlob(data)),
  handleCouponValidation: (data) => dispatch(couponValidation(data)),
  onSignUp: (data) => dispatch(signup(data)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPagePreBooking = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPagePreBooking;
