import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';

import css from './SectionPrebookingFooter.module.css';
import BRANDLOGOS from './img/brands.png';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { Button } from '../../../../components';
/*import SLIDER1 from './img/sl_1.png';
import SLIDER2 from './img/sl_2.png';
import SLIDER3 from './img/sl_3.png';
import SLIDER4 from './img/sl_4.png';
import SLIDER5 from './img/sl_5.png';*/
import { FormattedMessage } from 'react-intl';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionPrebookingFooter = props => {
  const { isEsianForm } = props;

  if (isEsianForm) {
    return (
      <>
        <div className={css.brandImages}>
          <img src={BRANDLOGOS} alt="Brand Logos" width={'80%'} />
        </div>
      </>
    );
  }

  const onAddService = value => {
    window.location.replace(value);
  };
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % 5);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide + 4) % 5);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 7000); // Auto slide every 3 seconds

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <>
      <div className={css.brandImages}>
        <h4 className={css.landingSubTItle}>
          <FormattedMessage id="SectionPrebookingFooter.subTitle1" />
        </h4>
        <img src={BRANDLOGOS} alt="Brand Logos" width={'80%'} />
      </div>
      <div className={css.esianSectiopn}>
        <h4 className={css.landingSubTItle}>
          <FormattedMessage id="SectionPrebookingFooter.subTitle2" />
        </h4>
        <div className={["slider1Prebooking", css.videoSection].join(' ')}>
          <div className="slide1-container">
          </div>
            <iframe
              width="100%"
              height="611px"
              src="https://www.youtube.com/embed/iFoy1LFFlCE?autoplay=1&amp;mute=1"
              title="L'Expérience Esa"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen=""
            ></iframe>
        </div>
      </div>
    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionPrebookingFooter.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionPrebookingFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionPrebookingFooter;
